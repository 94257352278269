import { Button } from "antd";
import PropTypes from "prop-types";

let styles = {
  backgroundColor: "black",
  color: "white",
  "&:hover": {
    backgroundColor: "red",
    border: "4px solid red",
  },
};

const PrimaryButton = ({ children, onClick, disabled, ...props }) => {
  if ("style" in props) {
    // eslint-disable-next-line react/prop-types
    styles = { ...styles, ...props.style };
    // eslint-disable-next-line react/prop-types,no-param-reassign
    delete props.style;
  }

  return (
    <Button onClick={onClick} style={styles} disabled={disabled} {...props}>
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  props: PropTypes.objectOf({
    style: PropTypes.bool,
  }),
};

PrimaryButton.defaultProps = {
  props: {
    style: {},
  },
  onClick: () => {},
  disabled: false,
};

export default PrimaryButton;
