import Register from "../views/public/Register";
import Login from "../views/public/Login";
import EmailVerification from "../views/public/EmailVerification";
import ResetPassword from "../views/public/ResetPassword";
import ResetPasswordConfirm from "../views/public/ResetPasswordConfirm";
import Profile from "../views/private/Profile";
import Papers from "../views/private/Papers";
import SinglePaper from "../views/private/SinglePaper";
import Users from "../views/private/Users";
import { Roles } from "../utilities/constants";
import ResendVerificationCode from "../views/public/ResendVerificationCode";
import CreatePaper from "../views/private/CreatePaper";

export const PublicRoutes = [
  {
    path: "/register",
    view: Register,
  },
  {
    path: "/login",
    view: Login,
  },
  {
    path: "/email-verification",
    view: EmailVerification,
  },
  {
    path: "/email-verification/resend",
    view: ResendVerificationCode,
  },
  {
    path: "/reset-password",
    view: ResetPassword,
  },
  {
    path: "/reset-password/confirm",
    view: ResetPasswordConfirm,
  },
];

export const PrivateRoutes = [
  {
    path: "/papers",
    view: Papers,
  },
  {
    path: "/papers/:id",
    view: SinglePaper,
  },
  {
    path: "/profile",
    view: Profile,
  },
  {
    path: "/users",
    view: Users,
    roles: [Roles.ADMIN.value],
  },
  {
    path: "/papers/wizzard",
    view: CreatePaper,
  }
];
