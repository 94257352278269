import { createSlice } from "@reduxjs/toolkit";

import { deleteFile, getFiles, uploadFile } from "./actions";

const initialState = {
  isLoading: false,
  data: null,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
      }))

      .addCase(deleteFile.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state.data || []).filter((i) => i.id !== meta.arg.fileId),
      }))

      .addCase(uploadFile.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data ? [...state.data, payload] : [payload],
      }));
  },
});

export default filesSlice.reducer;
