import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Typography } from "antd";

import Form from "../../components/form/Form";
import PrimaryButton from "../../components/PrimaryButton";
import {COUNTRIES, ResponsiveSizes} from "../../utilities/constants";
import { getCurrentUser, updateProfile } from "../../redux/users/actions";
import { setNotification } from "../../redux/utilities/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const phoneRegExp = /^\+\d+(?:[ ]*(?:ext|x)[ ]*\d+)?$/;

const profileSchema = yup
  .object()
  .shape({
    first_name: yup.string().typeError("The first name is not valid."),
    last_name: yup.string().typeError("The last name is not valid."),
    affiliation: yup.string().typeError("The affiliation is not valid."),
    phone: yup
      .string()
      .typeError("The phone number is not valid.")
      .test("test-phone-regex", "The phone number is not valid.", (val) => !(val && !phoneRegExp.test(val)))
      .nullable(),
    country: yup
      .string()
      .typeError("The country is not valid.")
      .test((val) => !val || COUNTRIES.map((i) => i.code).includes(val))
      .nullable(),
    profile_photo: yup.mixed().nullable(),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "first_name",
        label: "First Name",
        type: "input-text",
        isRequired: true,
        size: 11,
        styles: { marginRight: "8px", marginBottom: "30px" },
      },
      {
        name: "last_name",
        label: "Last Name",
        type: "input-text",
        isRequired: true,
        size: 11,
      },
      {
        name: "phone",
        label: "Phone",
        type: "input-tel",
        helpText: "The phone must have the country code (ex. +389).",
        size: 7,
        styles: { marginRight: "8px", marginBottom: "30px" },
      },
      {
        name: "affiliation",
        label: "Affiliation",
        type: "input-text",
        size: 7,
        styles: { marginRight: "8px" },
      },
      {
        name: "country",
        label: "Country",
        type: "select",
        placeholder: "Select a country",
        options: COUNTRIES.map((i) => ({ label: i.name, value: i.code })),
        filterOption: (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
        showSearch: true,
        size: 7,
      },
      {
        name: "profile_photo",
        label: "Profile Photo",
        accept: "image/jpg, image/jpeg, image/png",
        render: true,
        isImage: true,
        maxCount: 1,
        type: "file",
      },
    ],
  },
];

const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(profileSchema),
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getCurrentUser())
      .unwrap()
      .then((data) => {
        Object.keys(data).map((key) => form.setValue(key, data[key]));
      });
  }, []);

  const handleSubmit = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((i) => {
      if (i === "profile_photo") {
        if (values[i]?.file) {
          formData.append(i, values[i].file);
        }
      } else {
        formData.append(i, values[i]);
      }
    });

    dispatch(updateProfile({ values: formData, setError: form.setError }))
      .unwrap()
      .then((data) => {
        dispatch(setNotification({ message: "Your profile has been updated." }));
        form.setValue("profile_photo", data.profile_photo);
      })
      .catch(() => {});
  };

  return (
    <div style={{ width: width < ResponsiveSizes.SM ? "100%" : "50%", margin: "15px auto 0" }}>
      <div style={{ marginBottom: "20px" }}>
        <Title level={3} style={{ textAlign: "center" }}>
          Update your profile
        </Title>
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
        FormButtons={
          <div style={{ margin: "40px auto", textAlign: "center" }}>
            <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting} size="large" style={{ margin: "10px auto" }}>
              Submit
            </PrimaryButton>
          </div>
        }
      />
    </div>
  );
};

export default Profile;
