import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getFiles = createAsyncThunk("files/list", async ({ paperId }, { rejectWithValue }) => makeRequest(`/papers/${paperId}/files/`, "GET", null, rejectWithValue));

export const uploadFile = createAsyncThunk("files/upload", async ({ paperId, values }, { rejectWithValue }) => makeRequest(`/papers/${paperId}/files/`, "POST", values, rejectWithValue));

export const deleteFile = createAsyncThunk("files/delete", async ({ paperId, fileId }, { rejectWithValue }) =>
  makeRequest(`/papers/${paperId}/files/?file_id=${fileId}`, "DELETE", null, rejectWithValue),
);
