import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createPaper, deletePaperAction, getPaperById, getPapers, updatePaper } from "./actions";
import { isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";

const initialState = {
  loading: false,
  data: null,
};

export const papersSlice = createSlice({
  name: "papers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPapers.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
        loading: false,
      }))

      .addCase(deletePaperAction.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state?.data || []).filter((paper) => paper.id !== meta.arg),
        loading: false,
      }))

      .addCase(createPaper.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data ? [payload, ...state.data] : [payload],
        loading: false,
      }))

      .addMatcher(isAnyOf(updatePaper.fulfilled, getPaperById.fulfilled), (state, { payload }) => ({
        ...state,
        data: state.data
          ? state.data.map((paper) => {
              if (paper.id === payload.id) {
                return payload;
              }
              return paper;
            })
          : [payload],
        loading: false,
      }))
      .addMatcher(isPendingAction("papers"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("papers"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("papers"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default papersSlice.reducer;
