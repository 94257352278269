import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { setChangePasswordModal, setNotification } from "../redux/utilities/actions";
import Form from "./form/Form";
import { updateProfile } from "../redux/users/actions";

const changePasswordSchema = yup
  .object()
  .shape({
    password: yup.string().typeError("The password is not valid.").required("Please enter a password."),
    repeat_password: yup
      .string()
      .typeError("The repeat password is not valid.")
      .required("Please confirm your password.")
      .oneOf([yup.ref("password"), null], "The passwords must match."),
  })
  .required();

const changePasswordFormFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "password",
        label: "Password",
        isRequired: true,
        type: "input-password",
        size: 24,
      },
      {
        name: "repeat_password",
        label: "Repeat Password",
        isRequired: true,
        type: "input-password",
        size: 24,
      },
    ],
  },
];

const ChangePasswordModal = () => {
  const dispatch = useDispatch();
  const openChangePasswordModal = useSelector((state) => state.utils.changePasswordModal);

  const changePasswordForm = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: "onChange",
  });

  const submitPasswordChange = async () => {
    await changePasswordForm.trigger();
    if (changePasswordForm.formState.isValid) {
      dispatch(updateProfile(changePasswordForm.getValues()))
        .unwrap()
        .then(() => dispatch(setNotification({ message: "The password has been changed." })));
      dispatch(setChangePasswordModal(false));
    }
  };

  return (
    <div>
      <Modal title="Change your password" open={openChangePasswordModal} onCancel={() => dispatch(setChangePasswordModal(false))} onOk={submitPasswordChange}>
        <Form steps={changePasswordFormFields} form={changePasswordForm} isLoading={changePasswordForm.formState.isSubmitting} />
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
