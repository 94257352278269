import { Col, Row, Typography, Upload, Image } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

import ErrorBox from "./ErrorBox";

const { Dragger } = Upload;
const { Title } = Typography;

const FileUpload = ({ fieldDetails, form }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const files = watch(fieldDetails.name);

  const handlePreview = async (file) => {
    if (file.url) {
      window.open(file.url, "_blank");
    } else {
      const url = URL.createObjectURL(file.originFileObj);
      window.open(url, "_blank");
    }
  };

  const props = {
    name: "file",
    multiple: fieldDetails?.multiple,
    action: null,
    maxCount: fieldDetails?.maxCount,
    onPreview: handlePreview,
    accept: fieldDetails?.accept,
    beforeUpload: () => false,
  };

  const InputComponent = (
    <Controller
      name={fieldDetails.name}
      control={control}
      rules={{ required: fieldDetails.isRequired }}
      render={({ field }) => (
        <Dragger {...props} {...field} ref={null} fileList={files?.fileList}>
          <p className="ant-upload-drag-icon" style={{ marginBottom: '0' }}>
            <InboxOutlined/>
          </p>
          <p className="ant-upload-text">Click or drag a file to this area to upload</p>
        </Dragger>
      )}
    />
  );

  return (
    <>
      <Title level={5} style={{ marginTop: "5px" }}>
        {fieldDetails?.label}
        {fieldDetails?.isRequired ? <span> *</span> : ":"}
      </Title>
      {fieldDetails.render && files ? (
        <Row>
          <Col span={6} style={{ paddingRight: "15px" }}>
            {fieldDetails.isImage && <Image src={typeof files === "string" && files} />}
          </Col>
          <Col span={17}>{InputComponent}</Col>
        </Row>
      ) : (
        InputComponent
      )}

      <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
    </>
  );
};

FileUpload.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    helpText: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    maxCount: PropTypes.number,
    accept: PropTypes.string,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    isImage: PropTypes.bool,
    render: PropTypes.bool,
    hideLabel: PropTypes.bool,
    hideLabelInEdit: PropTypes.bool,
    showContent: PropTypes.bool,
    rows: PropTypes.number,
    showSearch: PropTypes.bool,
    filterOption: PropTypes.func,
    options: PropTypes.objectOf(PropTypes.any.isRequired),
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default FileUpload;
