import { useEffect, useRef, useState } from "react";

import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import PropTypes from "prop-types";

const getColumnSearchProps = (dataIndex, columns, searchInput, handleSearch, handleReset, searchedColumn, searchText) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${columns.find((i) => i.dataIndex === dataIndex).title.toLowerCase()}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1677ff" : undefined,
      }}
    />
  ),
  onFilter: (value, record) => _.get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex && dataIndex !== "keywords" ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

const CustomTable = ({ data, columns, rowClassNameFunction = null }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [modifiedColumns, setModifiedColumns] = useState([]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    if (columns) {
      setModifiedColumns(
        columns.map((i) => {
          if (i?.addSearch) {
            return { ...i, ...getColumnSearchProps(i.dataIndex, columns, searchInput, handleSearch, handleReset, searchedColumn, searchText) };
          }
          return i;
        }),
      );
    }
  }, [columns]);

  return <Table scroll={{
        x: modifiedColumns.reduce((acc, i) => {
            // eslint-disable-next-line no-param-reassign
            acc += i.width;
            return acc;
        }, 0),
      }} dataSource={data || []} columns={modifiedColumns} rowClassName={rowClassNameFunction} />;
};

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowClassNameFunction: PropTypes.func,
};

CustomTable.defaultProps = {
  rowClassNameFunction: null,
};

export default CustomTable;
