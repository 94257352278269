import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";

import { deletePaperAction, getPapers } from "../../redux/papers/actions";
import {PaperStatuses, ResponsiveSizes, Roles} from "../../utilities/constants";
import PrimaryButton from "../../components/PrimaryButton";
import { setNotification } from "../../redux/utilities/actions";
import { currentUserSelector } from "../../redux/users/selectors";
import Table from "../../components/Table";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";


const Papers = () => {
  const papers = useSelector((state) => state.papers.data);
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPapers());
  }, []);

  const deletePaper = (paperId) => {
    dispatch(deletePaperAction(paperId))
      .unwrap()
      .then(() => dispatch(setNotification({ message: "The paper has been deleted." })));
  };

  useEffect(() => {
    const baseColumns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: 300,
        ellipsis: true,
        addSearch: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: width < ResponsiveSizes.SM ? 150 : 100,
        filters: PaperStatuses.asList().map((i) => ({ text: i.label, ...i })),
        onFilter: (value, record) => record.status === value,
        render: (_, record) => PaperStatuses.getLabel(record.status),
      },
      {
        title: "Keywords",
        dataIndex: "keywords",
        key: "keywords",
        width: 200,
        filters: (papers || []).reduce((acc, i) => {
          i.keywords.forEach((keyword) => {
            if (!acc.map((innerVal) => innerVal.value).includes(keyword)) {
              acc.push({ text: keyword, value: keyword });
            }
          });
          return acc;
        }, []),
        onFilter: (value, record) => record.keywords.includes(value),
        render: (_, record) => <span style={{ width: "50%" }}>{record.keywords ? record.keywords.map((i) => <Tag key={i}>{i}</Tag>) : "None"}</span>,
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_date",
        width: width < ResponsiveSizes.SM ? 150 : 80,
        render: (_, record) => new Date(record.created_at).toLocaleString(),
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        defaultSortOrder: "descend",
      },
      {
        title: "Action",
        key: "action",
        width: width < ResponsiveSizes.SM ? 150 : 120,
        render: (_, record) => (
          <Space size="middle">
            <a onClick={() => navigate(`/papers/${record.id}`)}><SearchOutlined /> View</a>
            <a onClick={() => deletePaper(record.id)}>
              <DeleteOutlined /> Delete
            </a>
          </Space>
        ),
      },
    ];

    if (currentUser) {
      setColumns(
        currentUser?.role === Roles.ADMIN
          ? [
              ...baseColumns.slice(0, 4),
              {
                title: "Author",
                width: width < ResponsiveSizes.SM ? 180 : 140,
                dataIndex: ["created_by", "email"],
                key: "created_by",
                render: (_, record) => record.created_by.email,
                addSearch: true,
              },
              ...baseColumns.slice(4),
            ]
          : baseColumns,
      );
    }
  }, [currentUser]);

  const rowClassName = (record) => (new Date(record.created_at) > new Date(currentUser?.last_login) && currentUser?.role === Roles.ADMIN ? "highlighted-row" : "");

  return (
    <div>
      <div style={{ float: "right", marginBottom: '7px' }}>
        <PrimaryButton onClick={() => navigate('/papers/wizzard/')} size="large">
          Add Paper
        </PrimaryButton>
      </div>
      {papers && currentUser && <Table data={papers} columns={columns} rowClassNameFunction={rowClassName} />}
    </div>
  );
};

export default Papers;
