import PropTypes from "prop-types";
import {Col, Row, Typography} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const ErrorBox = ({ message, showIcon }) => {
    const { width } = useWindowDimensions();

    return message && (
      <Row style={{ color: "red" }}>
         {showIcon && (
             <Col span={width < 900 ? 2 : 1}>
              <ExclamationCircleOutlined style={{ marginTop: '12px' }} />
            </Col>
         )}
          <Col span={22}>
            <Typography.Title level={5} style={{ color: "red", marginTop: "5px" }}>
                {message}
            </Typography.Title>
          </Col>
      </Row>
  );
}


ErrorBox.propTypes = {
  message: PropTypes.string,
  showIcon: PropTypes.bool,
};

ErrorBox.defaultProps = {
  message: "",
  showIcon: true,
};

export default ErrorBox;
