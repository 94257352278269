import { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu } from "antd";
import {
    FileTextOutlined,
    LockOutlined,
    LogoutOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";

import { logOut } from "../../redux/authentication/actions";
import { setChangePasswordModal, setNotification } from "../../redux/utilities/actions";
import { getNotifications } from "../../redux/notifications/actions";
import { getCurrentUser } from "../../redux/users/actions";
import {ResponsiveSizes, Roles} from "../../utilities/constants";
import { currentUserSelector } from "../../redux/users/selectors";
import ChangePasswordModal from "../ChangePasswordModal";
import Notifications from "../Notifications";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const { Header, Content, Footer, Sider } = Layout;

const AuthenticatedLayout = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();

  const MenuItems = [
    { label: "Users", key: "/users", icon: <TeamOutlined />, roles: [Roles.ADMIN] },
    { label: "Papers", key: "/papers", icon: <FileTextOutlined /> },
    { label: "Profile", key: "/profile", icon: <UserOutlined /> },
    { label: "Change Password", key: "change-password", icon: <LockOutlined /> },
    { label: "Log Out", key: "/logout", icon: <LogoutOutlined /> },
  ];

  useEffect(() => {
    if (!currentUser) {
      dispatch(getCurrentUser());
    }

    // eslint-disable-next-line no-return-await
    const notificationsInterval = setInterval(async () => await dispatch(getNotifications()), 3 * 60 * 1000);

    return () => {
      clearInterval(notificationsInterval);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const handleMenuItemClick = (itemKey) => {
    if (itemKey === "/logout") {
      dispatch(logOut())
        .unwrap()
        .then(() => {
          dispatch(setNotification({ message: "You've successfully logged out." }));
          navigate("/login");
        });
    } else if (itemKey === "change-password") {
      dispatch(setChangePasswordModal(true));
    } else {
      setSelectedKeys([itemKey]);
      navigate(itemKey);
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="sm"
        collapsedWidth={width <= ResponsiveSizes.SM ? '0' : '70'}
        style={{
          zIndex: '2',
          height: height - 60,
          position: "fixed",
          left: 0,
          top: 60,
          paddingTop: "20px",
          bottom: 0,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        {currentUser && (
          <Menu
            theme="dark"
            defaultSelectedKeys={selectedKeys}
            selectedKeys={selectedKeys}
            onClick={(e) => handleMenuItemClick(e.key)}
            mode="inline"
            items={MenuItems.filter((i) => !i?.roles || i.roles.includes(currentUser?.role))}
          />
        )}
      </Sider>
      <Layout>
        <Header
          style={{
            position: "fixed",
            top: 0,
            zIndex: 1,
            left: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: "auto" }}>
              <Notifications />
          </div>
        </Header>
        <Content style={{ marginLeft: width <= ResponsiveSizes.SM ? "40px" : (collapsed ? "100px" : "230px"), marginTop: "70px", transition: "margin-left 0.3s" }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            <Outlet />
            <ChangePasswordModal />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        />
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
