import { createAsyncThunk } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-cycle
import { makeRequest } from "../../utilities/api";

export const register = createAsyncThunk("authentication/register", async ({ values, setError }, { rejectWithValue }) => makeRequest("/auth/register/", "POST", values, rejectWithValue, setError));

export const login = createAsyncThunk("authentication/login", ({ values, setError }, { rejectWithValue }) => makeRequest("/auth/login/", "POST", values, rejectWithValue, setError));

export const logOut = createAsyncThunk("authentication/logout", (values, { rejectWithValue }) => makeRequest("/auth/logout/", "POST", values, rejectWithValue));

export const verifyAccount = createAsyncThunk("authentication/emailVerification", (values, { rejectWithValue }) => makeRequest("/auth/email-verification/", "POST", values, rejectWithValue));

export const resendVerificationCode = createAsyncThunk("authentication/emailResendVerification", async ({ email }, { rejectWithValue }) =>
  makeRequest("/auth/registration/resend-email/", "POST", { email }, rejectWithValue),
);

export const resetPassword = createAsyncThunk("authentication/resetPassword", async ({ values, setError }, { rejectWithValue }) =>
  makeRequest("/auth/password/reset/", "POST", values, rejectWithValue, setError),
);

export const confirmResetPassword = createAsyncThunk("authentication/confirmResetPassword", async ({ values, setError }, { rejectWithValue }) =>
  makeRequest("/auth/password/reset/confirm/", "POST", values, rejectWithValue, setError),
);
