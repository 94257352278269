import {useEffect, useRef, useState} from "react";

import {Badge, List, Popover, Tabs, Typography} from "antd";
import {BellOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {getNotifications, readNotificationsAction} from "../redux/notifications/actions";
import useWindowDimensions from "../utilities/hooks/useWindowDimensions";

const OutsideClickHandler = ({ onOutsideClick, children }) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

OutsideClickHandler.propTypes = {
    onOutsideClick: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
}

const Notifications = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const unreadNotifications = useSelector((state) => state.notifications.data.filter((i) => !i.is_read));
    const readNotifications = useSelector((state) => state.notifications.data.filter((i) => i.is_read))
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

      useEffect(() => {
        dispatch(getNotifications());
      }, [isPopoverOpen]);

    return (
        <OutsideClickHandler onOutsideClick={() => setIsPopoverOpen(false)}>
            <Popover
              style={{ marginRight: width < 1300 ? "15px" : "0" }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              open={isPopoverOpen}
              onClick={() => setIsPopoverOpen((oldVal) => !oldVal)}
              content={
                <div style={{ maxHeight: "300px", width: width < 1300 ? "300px" : "400px" }}>
                    <Tabs defaultActiveKey={1} items={[
                        {
                            key: 1,
                            label: "New",
                            children: (
                                <List
                                  itemLayout="horizontal"
                                  locale={{ emptyText: "There are no new notifications." }}
                                  dataSource={unreadNotifications || []}
                                  renderItem={(item) => (
                                    <List.Item>
                                      <Typography.Text mark={!item.is_read}>{item.title}</Typography.Text>
                                    </List.Item>
                                  )}
                                />
                            )
                        },
                        {
                            key: 2,
                            label: "Archived",
                            children: (
                                <List
                                  itemLayout="horizontal"
                                  locale={{ emptyText: "There are no archived notifications." }}
                                  dataSource={readNotifications || []}
                                  renderItem={(item) => (
                                    <List.Item>
                                      <Typography.Text>{item.title}</Typography.Text>
                                    </List.Item>
                                  )}
                                />
                            )
                        }
                    ]}
                />
                </div>
              }
              onOpenChange={(isOpen) => {
                if (!isOpen && unreadNotifications.length > 0) {
                  dispatch(readNotificationsAction(unreadNotifications.map((i) => ({ ...i, is_read: true }))));
                }
              }}
              title="Notifications"
              trigger="click"
            >
              <Badge count={unreadNotifications.length} style={{ cursor: "pointer" }}>
                <BellOutlined style={{ cursor: "pointer", color: "white", fontSize: "20px" }} />
              </Badge>
            </Popover>
        </OutsideClickHandler>
    )
}

export default Notifications;
