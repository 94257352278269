import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Form from "../../components/form/Form";
import PrimaryButton from "../../components/PrimaryButton";
import { setNotification } from "../../redux/utilities/actions";
import { confirmResetPassword } from "../../redux/authentication/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const resetPasswordConfirm = yup
  .object()
  .shape({
    password: yup.string().typeError("The password is not valid.").required("Please enter a password."),
    repeat_password: yup
      .string()
      .typeError("The repeat password is not valid.")
      .required("Please confirm your password.")
      .oneOf([yup.ref("password"), null], "The passwords must match."),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "password",
        label: "Password",
        isRequired: true,
        type: "input-password",
        size: 24,
      },
      {
        name: "repeat_password",
        label: "Repeat Password",
        isRequired: true,
        type: "input-password",
        size: 24,
      },
    ],
  },
];

const { Title } = Typography;

const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(resetPasswordConfirm),
    mode: "onChange",
  });

  useEffect(() => {
    if (!searchParams.has("token")) {
      dispatch(setNotification({ message: "It looks like the link you've clicked is not valid. Please try again.", type: "error" }));
      navigate("/reset-password");
    }
  }, [navigate, searchParams]);

  const handleSubmit = (values) => {
    dispatch(confirmResetPassword({ values: { ...values, token: searchParams.get("token") } }))
      .unwrap()
      .then((data) => {
        dispatch(setNotification({ message: data.message }));
        navigate("/login");
      });
  };

  return (
    <div>
      <div style={{ marginBottom: "30px", textAlign: "center" }}>
        <Title level={1}>Reset Password</Title>
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
        FormButtons={
          <Row>
            <Col span={width < 900 ? 24 : 17}>
              <Typography.Link onClick={() => navigate("/login")}>Remember your password?&nbsp;Log In</Typography.Link>
            </Col>
            <Col span={width < 900 ? 24 : 7} style={{ marginTop: '15px', textAlign: 'center' }}>
              <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                Reset password
              </PrimaryButton>
            </Col>
          </Row>
        }
      />
    </div>
  );
};

export default ResetPasswordConfirm;
