import PropTypes from "prop-types";

import TextField from "../form/TextField";

const Abstract = ({ isEdit, form }) => (
  <div>
    <TextField
      form={form}
      isLoading={form.formState.isLoading}
      isEdit={isEdit}
      fieldDetails={{
        name: "abstract",
        label: "Abstract",
        isRequired: true,
        hideLabelInEdit: true,
        hideLabel: true,
        showContent: true,
        rows: 13,
        type: "input-text-area",
      }}
    />
  </div>
);

Abstract.propTypes = {
  isEdit: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

Abstract.defaultProps = {
  isEdit: false,
};

export default Abstract;
