import { createSlice } from "@reduxjs/toolkit";
import { addComment, deleteComment, getComments, updateComment } from "./actions";
import { isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";

const initialState = {
  loading: false,
  data: null,
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getComments.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
        loading: false,
      }))

      .addCase(addComment.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data ? [...state.data, payload] : [payload],
        loading: false,
      }))

      .addCase(updateComment.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data
          ? state.data.map((i) => {
              if (i.id === payload.id) return payload;
              return i;
            })
          : [payload],
        loading: false,
      }))

      .addCase(deleteComment.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state.data || []).filter((i) => i.id !== meta.arg.commentId),
        loading: false,
      }))

      .addMatcher(isPendingAction("papers"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("papers"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("papers"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default commentsSlice.reducer;
