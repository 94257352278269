import {Col} from "antd";

import TextField from "./TextField";
import Banner from "./Banner";
import SelectField from "./SelectField";
import Tags from "./Tags";
import FileUpload from "./FileUpload";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";
import {ResponsiveSizes} from "../../utilities/constants";
import Checkbox from "./Checkbox";

const FormFields = ({ page, isLoading, form, isEdit }) => {
  const { fields } = page;
  const { width } = useWindowDimensions();

  const constructField = (field) => {
    switch (field.type) {
      case "input-password":
      case "input-email":
      case "input-number":
      case "input-url":
      case "input-text":
      case "input-text-area":
      case "input-tel":
        return <TextField fieldDetails={field} isLoading={isLoading} form={form} isEdit={isEdit} />;
      case "select":
        return <SelectField fieldDetails={field} isLoading={isLoading} form={form} />;
      case "banner":
        return <Banner field={field} />;
      case "file":
        return <FileUpload fieldDetails={field} form={form} isLoading={isLoading} />;
      case "tags":
        return <Tags fieldDetails={field} form={form} isEdit={isEdit} />;
      case "checkbox":
        return <Checkbox fieldDetails={field} form={form} />
      case "empty":
        return <div style={{ paddingBottom: "30px" }} />;
      default:
        throw new Error(`${field.type} is not a valid field type.`);
    }
  };

  const renderFields = () => {
    let index = null;
    return fields?.map((field) => {
      if (Array.isArray(field)) {
        if (index !== null) {
          index += 1;
        } else {
          index = 0;
        }
      }
      return (
        <Col
          span={width <= ResponsiveSizes.SM ? 24 : field?.size || 24}
          key={field.name}
          style={{
            margin: field.type === "file" ? "auto 0 0 auto" : field.margin ? field.margin : "5px 0",
            ...field?.styles,
          }}
        >
          {constructField(field)}
        </Col>
      );
    });
  };

  return renderFields();
};

export default FormFields;
