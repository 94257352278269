import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row, Timeline, Typography } from "antd";
import moment from "moment/moment";
import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";

import Form from "../form/Form";
import PrimaryButton from "../PrimaryButton";
import { setNotification } from "../../redux/utilities/actions";
import { deleteFile, getFiles, uploadFile } from "../../redux/files/actions";

const { Title } = Typography;

const fileUploadSchema = yup
  .object()
  .shape({
    file: yup.mixed().required("You must select a file."),
  })
  .required();

const fileUploadSteps = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "file",
        label: "File",
        accept: "application/pdf",
        maxCount: 1,
        isRequired: true,
        type: "file",
      },
    ],
  },
];

const Files = ({ paperId }) => {
  const files = useSelector((state) => (state.files.data || []).filter((i) => i.paper === paperId));
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    fileId: null,
  });

  const form = useForm({
    resolver: yupResolver(fileUploadSchema),
    mode: "onChange",
    defaultValues: {
      file: null,
    },
  });

  useEffect(() => {
    dispatch(getFiles({ paperId }));
  }, [paperId]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("file", values.file.file);
    formData.append("paper", paperId);

    dispatch(uploadFile({ paperId, values: formData })).then(() => {
      dispatch(setNotification({ message: "The file has been uploaded." }));
      form.reset({ file: null });
    });
  };

  function sortByVersion(a, b) {
    return b.version - a.version;
  }

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Form
          steps={fileUploadSteps}
          form={form}
          isLoading={form.formState.isSubmitting}
          onSubmit={handleSubmit}
          FormButtons={
            <div style={{ textAlign: "center", marginTop: '10px' }}>
              <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                Submit
              </PrimaryButton>
            </div>
          }
        />
      </div>
      {files ? (
        <Timeline
          mode="left"
          items={files.sort(sortByVersion).map((i) => ({
            children: (
              <div>
                <Row>
                  <Col>
                    <a rel="noreferrer" href={i.file} target="_blank">
                      {i.name}
                    </a>
                  </Col>
                  {i.version !== 0 && (
                    <Col style={{ marginLeft: "15px" }}>
                      <DeleteOutlined style={{ color: "red" }} onClick={() => setDeleteModal({ open: true, fileId: i.id })} />
                    </Col>
                  )}
                </Row>
              </div>
            ),
            label: `Version ${i.version} - ${moment(i.created_at).format("MMM Do YYYY, HH:mm")}`,
          }))}
        />
      ) : (
        <Title level={3} style={{ fontWeight: "300", color: "silver" }}>
          There are no files.
        </Title>
      )}
      <Modal
        open={deleteModal.open}
        title={`Are you sure you want to delete ${files.find((i) => i.id === deleteModal.fileId)?.name}?`}
        onCancel={() => setDeleteModal({ open: false, fileId: null })}
        onOk={() => {
          dispatch(deleteFile({ paperId, fileId: deleteModal.fileId }));
          setDeleteModal({ open: false, fileId: null });
        }}
      />
    </div>
  );
};

Files.propTypes = {
  paperId: PropTypes.number.isRequired,
};

export default Files;
