/* eslint-disable no-underscore-dangle */
import axios from "axios";

import { LocalStorageName } from "./constants";
import { setNotification } from "../redux/utilities/actions";

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const clientApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const refreshApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

clientApi.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshRequest = await refreshApi.post("/auth/refresh/");
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            access_expiration: refreshRequest.data.access_expiration,
            refresh_expiration: refreshRequest.data.refresh_expiration,
          }),
        );
        return clientApi(originalRequest);
      } catch (err) {
        window.localStorage.removeItem(LocalStorageName);
        window.location.href = "/login";
      }
    } else {
      throw error;
    }
  },
);

// eslint-disable-next-line consistent-return
export const makeRequest = async (url, method, payload, rejectWithValue, setError = null) => {
  try {
    const data = await clientApi(url, {
      method,
      data: payload,
    });
    return data?.data;
  } catch (error) {
    const errorData = {
      status: error.response.status,
      message: error.response?.data,
    };
    if (errorData.status === 500) {
      store.dispatch(setNotification({ message: "Internal Server Error" }));
    } else {
      Object.keys(errorData.message).forEach((i) => {
        if (i !== "non_field_errors" && setError) {
          setError(i, { message: Array.isArray(errorData.message[i]) ? errorData.message[i][0] : errorData.message[i] });
        } else {
          store.dispatch(setNotification({ message: Array.isArray(errorData.message[i]) ? errorData.message[i][0] : errorData.message[i], type: "error" }));
        }
      });
    }

    return rejectWithValue(errorData.message);
  }
};
