import { Col, Layout, Row } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const DefaultLayout = () => {
  const { Header, Content, Footer } = Layout;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/papers");
    }
  }, [isAuthenticated]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header>
        <Row>
          <Col />
          <Col />
        </Row>
      </Header>
      <Content>
        <div
          style={{
            width: width < 700 ? "90%" : "30%",
            margin: "auto",
            position: "absolute",
            top: "20%",
            left: width < 700 ? "20px" : "35%",
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer>
        <Row>
          <Col />
          <Col />
        </Row>
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;
