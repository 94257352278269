import { useEffect, useRef, useState } from "react";

import { Input, Tag, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import _ from "lodash";

import ErrorBox from "./ErrorBox";

const { Title, Text } = Typography;

const Tags = ({ fieldDetails, form, isEdit }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const { watch, setValue, formState: { errors } } = form;
  const tags = watch(fieldDetails.name) || [];

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => {
    setValue(
      fieldDetails.name,
      tags.filter((tag) => tag !== removedTag),
    );
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setValue(fieldDetails.name, [...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable={isEdit}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{ marginBottom: '6px' }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };

  return (
    <>
      {(isEdit || !fieldDetails?.hideLabel) && (
        <Title level={5} style={{ marginTop: "5px" }}>
          {fieldDetails?.label}
          {isEdit ? fieldDetails?.isRequired && <span> *</span> : ":"}
        </Title>
      )}
      <div
        style={{
          marginBottom: 16,
        }}
      >
        {(tags || []).map(forMap)}
      </div>
      {isEdit &&
        (inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={{
              width: 78,
            }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag onClick={showInput}>
            <PlusOutlined /> New Tag
          </Tag>
        ))}
      {isEdit && (
          <div>
            <Text style={{ fontSize: "14px", color: "grey" }}>{fieldDetails?.helpText}</Text>
          </div>
      )}
      <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
    </>
  );
};

Tags.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    helpText: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    hideLabel: PropTypes.bool,
    hideLabelInEdit: PropTypes.bool,
    showContent: PropTypes.bool,
    rows: PropTypes.number,
    showSearch: PropTypes.bool,
    filterOption: PropTypes.func,
    options: PropTypes.objectOf(PropTypes.any.isRequired),
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isEdit: PropTypes.bool,
};

Tags.defaultProps = {
  isEdit: true,
};

export default Tags;
