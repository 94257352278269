import {Typography} from "antd";
import {useNavigate} from "react-router-dom";

import PrimaryButton from "./PrimaryButton";
import useWindowDimensions from "../utilities/hooks/useWindowDimensions";

const { Title, Text } = Typography;

const ErrorBoundary = () => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    return (
        <div style={{ height: '70vh' }}>
          <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', width: width < 900 ? '100%' : '60%', textAlign: 'left', margin: 'auto' }}>
            <Title level={4} style={{ marginTop: '18px' }}>
              Something went wrong here.
            </Title>
            <Text style={{ marginTop: '12px' }}>
                If you see this, please report this error at <strong>support@roads.mare.org.mk</strong>. To continue browsing, you can go back and continue to use our application.
            </Text>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
                <PrimaryButton onClick={() => navigate(-1)}>
                    Back
                </PrimaryButton>
            </div>
          </div>
        </div>
    )
};

export default ErrorBoundary;
