import { Select, Typography } from "antd";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

import ErrorBox from "./ErrorBox";

const { Title } = Typography;

const SelectField = ({ fieldDetails, form }) => {
  const {
    control,
    formState: { errors },
  } = form;
  return (
    <>
      {!fieldDetails?.hideLabel && (
        <Title level={5} style={{ marginTop: "5px" }}>
          {fieldDetails?.label}
          {fieldDetails?.isRequired ? <span> *</span> : ":"}
        </Title>
      )}
      <Controller
        name={fieldDetails.name}
        control={control}
        rules={{ required: fieldDetails.isRequired }}
        render={({ field }) => (
          <Select
            style={{ width: "100%" }}
            mode={fieldDetails?.multiple && "multiple"}
            showSearch={fieldDetails?.showSearch}
            placeholder={fieldDetails?.placeholder}
            optionFilterProp="children"
            filterOption={fieldDetails?.filterOption}
            options={fieldDetails?.options}
            {...field}
          />
        )}
      />
      <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
    </>
  );
};

SelectField.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    helpText: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    hideLabel: PropTypes.bool,
    hideLabelInEdit: PropTypes.bool,
    showContent: PropTypes.bool,
    rows: PropTypes.number,
    showSearch: PropTypes.bool,
    filterOption: PropTypes.func,
    options: PropTypes.objectOf(PropTypes.any.isRequired),
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default SelectField;
