import { createSlice } from "@reduxjs/toolkit";

import { isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { getCurrentUser, getUsers, patchUser } from "./actions";
import { login } from "../authentication/actions";

const initialState = {
  loading: false,
  currentUserId: null,
  data: [],
};

export const usersSlice = createSlice({
  name: "papers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => ({
        ...state,
        data: [...(state?.data || []).filter((i) => i.id !== payload.id), payload],
        currentUserId: payload.id,
        loading: false,
      }))

      .addCase(login.fulfilled, (state, { payload }) => ({
        ...state,
        data: [...(state?.data || []).filter((i) => i.id !== payload.user.id), payload.user],
        currentUserId: payload.user.id,
        loading: false,
      }))

      .addCase(getUsers.fulfilled, (state, { payload }) => {
        const newUserIds = (payload || []).map((i) => i.id);
        return ({
        ...state,
        data: [...(state?.data.filter((i) => !newUserIds.includes(i.id)) || []), ...(payload || [])],
        loading: false,
      })
      })

      .addCase(patchUser.fulfilled, (state, { payload }) => ({
        ...state,
        data: (state.data || []).map((i) => {
          if (i.id === payload.id) {
            return payload;
          }
          return i;
        }),
        loading: false,
      }))

      .addMatcher(isPendingAction("users"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("users"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("users"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default usersSlice.reducer;
