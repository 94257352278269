import { createSlice } from "@reduxjs/toolkit";

import { setChangePasswordModal, setNotification } from "./actions";

const initialState = {
  notification: null,
  changePasswordModal: false,
};

export const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setNotification, (state, { payload }) => ({
        ...state,
        notification: payload,
      }))
      .addCase(setChangePasswordModal, (state, { payload }) => ({
        ...state,
        changePasswordModal: payload,
      }));
  },
});

export default utilitiesSlice.reducer;
