import { createSlice } from "@reduxjs/toolkit";
import { getNotifications, getUnreadNotifications, readNotificationsAction } from "./actions";

const initialState = {
  isLoading: false,
  data: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => ({
      ...state,
      data: payload,
    }));
    builder.addCase(getUnreadNotifications.fulfilled, (state, { payload }) => {
      const notificationIds = payload.map((i) => i.id);
      return {
        ...state,
        data: [...(state.data || []).filter((i) => !notificationIds.includes(i.id)), ...payload],
      };
    });
    builder.addCase(readNotificationsAction.fulfilled, (state, { payload }) => {
      const notificationIds = payload.map((i) => i.id);
      return {
        ...state,
        data: state.data.map((i) => {
          if (notificationIds.includes(i.id)) {
            return { ...i, is_read: true };
          }
          return i;
        }),
      };
    });
  },
});

export default notificationsSlice.reducer;
