import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Typography} from "antd";

import {createPaper} from "../../redux/papers/actions";
import {setNotification} from "../../redux/utilities/actions";
import PrimaryButton from "../../components/PrimaryButton";
import Form from "../../components/form/Form";
import {StaticPages} from "../../utilities/constants";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const { Title } = Typography;

const paperFormSchema = yup
  .object()
  .shape({
    authors_approval: yup.bool().typeError("The authors approval field is not valid.").oneOf([true], "The submission must be approved by all authors.").required("The submission must be approved by all authors."),
    copyright_permissions: yup.bool().typeError("The copyright permissions field is not valid.").oneOf([true], "You must have all copyright permissions.").required("You must have all copyright permissions."),
    manuscript_spell_grammar_check: yup.bool().typeError("The manuscript grammar check field is not valid.").oneOf([true], "You must check your manuscript from grammar and spell checks.").required("You must check your manuscript from grammar and spell checks."),
    references_submitted: yup.bool().typeError("The references field is not valid.").oneOf([true], "You must add all references in the paper.").required("You must add all references in the paper."),
    editor_comments: yup.string().typeError("The editor comments field is not valid."),
    privacy_consent: yup.bool().typeError("The privacy consent field is not valid.").oneOf([true], "You must consent to our privacy policy.").required("You must consent to our privacy policy."),
    title: yup.string().typeError("The title is not valid.").required("The title is required."),
    subtitle: yup.string().typeError("The subtitle is not valid."),
    authors: yup.string().typeError("The authors is not valid.").required("The authors is required."),
    keywords: yup.array().typeError("The keywords field is not valid.").min(1, "You must enter at least one keyword.").required("The keywords are required."),
    abstract: yup.string().typeError("The abstract field is not valid.").required("The abstract is required."),
    file: yup.mixed().required("The file is required."),
  })
  .required();

const paperFormFields = [
  {
    stepNo: 1,
    fullWidth: true,
    title: 'Paper Requirements',
    fields: [
      {
        type: "banner",
        contents: [
          {
            type: 'h1',
            content: "Submission Requirements"
          },
          {
            type: "p",
            content: "You must read and acknowledge that you've completed the requirements below before proceeding.\n"
          }
        ]
      },
      {
        name: "authors_approval",
        label: "The submission has been approved by all authors of the manuscript.",
        type: "checkbox",
        isRequired: true,
      },
      {
        name: "copyright_permissions",
        label: "Permission has been obtained for use of copyrighted material from other sources (including the Internet).",
        type: "checkbox",
        isRequired: true,
      },
      {
        name: "manuscript_spell_grammar_check",
        label: "Manuscript has been spell checked and grammar checked.",
        type: "checkbox",
        isRequired: true,
      },
       {
        name: "references_submitted",
        label: "All references mentioned in the Reference List are cited in the text, and vice versa.",
        type: "checkbox",
        isRequired: true,
      },
       {
        name: "editor_comments",
        label: "Comments for the Editor",
        type: "input-text-area",
        rows: 6,
        showContent: true,
      },
      {
        name: "privacy_consent",
        label: <span>Yes, I agree to have my data collected and stored according to the <a href={StaticPages.PRIVACY_POLICY} target="_blank" rel="noreferrer">privacy statement</a>.</span>,
        type: "checkbox",
        isRequired: true,
      },
    ],
  },
    {
    stepNo: 2,
    fullWidth: true,
    title: 'Paper Metadata',
    fields: [
      {
        name: "title",
        label: "Title",
        type: "input-text",
        isRequired: true,
        showContent: true,
      },
      {
        name: "subtitle",
        label: "Subtitle",
        type: "input-text",
        showContent: true,
      },
      {
        name: "abstract",
        label: "Abstract",
        isRequired: true,
        showContent: true,
        rows: 7,
        type: "input-text-area",
      },
      {
        name: "authors",
        label: "Authors",
        type: "input-text",
        helpText: "Separate the authors using a comma (,).",
        isRequired: true,
        showContent: true,
        size: 11,
      },
      {
        name: "keywords",
        label: "Keywords",
        helpText: "Enter a keyword and press 'Enter' after each one.",
        type: "tags",
        isRequired: true,
        size: 11,
        margin: "5px 20px 0",
      },
    ],
  },
      {
    stepNo: 3,
    fullWidth: true,
    title: 'Paper File',
    fields: [
      {
        name: "file",
        label: "File",
        accept: "application/pdf",
        maxCount: 1,
        isRequired: true,
        type: "file",
      },
    ],
  },
];

const CreatePaper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(paperFormSchema),
    mode: "onChange",
      reValidateMode: 'onChange',
    defaultValues: {
      authors_approval: false,
      title: "",
      authors: "",
      keywords: [],
      abstract: "",
      file: null,
    },
  });

    const handleSubmit = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "file") {
        formData.append(key, values[key].file);
      } else if (key === "keywords") {
        values[key].forEach((keyword, index) => formData.append(`keywords[${index}]`, keyword));
      } else {
        formData.append(key, values[key]);
      }
    });
    dispatch(createPaper({ values: formData, setError: form.setError }))
      .unwrap()
      .then(() => {
        dispatch(setNotification({ message: "The paper has been created." }));
        navigate('/papers');
        form.reset();
      });
  };

    return (
        <div style={{ width: width < 900 ? '100%' : '60%', margin: 'auto' }}>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Title level={4}>Create a new paper and submit it for review.</Title>
          </div>
          <Form
          steps={paperFormFields}
          form={form}
          isLoading={form.formState.isSubmitting}
          onSubmit={handleSubmit}
          FormButtons={
             <PrimaryButton htmlType="submit" style={{ float: 'left' }} disabled={form.formState.isSubmitting} size="large">
                Submit
              </PrimaryButton>
          }
        />
        </div>
    )
}

export default CreatePaper;
