import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getPapers = createAsyncThunk("papers/list", (_, { rejectWithValue }) => makeRequest("/papers/", "GET", null, rejectWithValue));

export const getPaperById = createAsyncThunk("papers/retrieve", (id, { rejectWithValue }) => makeRequest(`/papers/${id}/`, "GET", null, rejectWithValue));

export const createPaper = createAsyncThunk("papers/create", ({ values, setError }, { rejectWithValue }) => makeRequest("/papers/", "POST", values, rejectWithValue, setError));

export const updatePaper = createAsyncThunk("papers/update", ({ values, setError }, { rejectWithValue }) => makeRequest(`/papers/${values.id}/`, "PATCH", values, rejectWithValue, setError));

export const deletePaperAction = createAsyncThunk("papers/delete", (id, { rejectWithValue }) => makeRequest(`/papers/${id}/`, "DELETE", null, rejectWithValue));
