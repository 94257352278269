import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Form from "../../components/form/Form";
import PrimaryButton from "../../components/PrimaryButton";
import { register } from "../../redux/authentication/actions";
import { setNotification } from "../../redux/utilities/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const registerSchema = yup
  .object()
  .shape({
    email: yup.string().typeError("The email is not valid.").email("Please enter a valid email.").required("Please enter a valid email."),
    password: yup.string().typeError("The password is not valid.").required("Please enter a password.").min(8, "The password must be at least 8 characters long."),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "email",
        label: "Email address",
        type: "input-email",
        isRequired: true,
        size: 24,
      },
      {
        name: "password",
        label: "Password",
        isRequired: true,
        type: "input-password",
        size: 24,
      },
    ],
  },
];

const { Title, Link } = Typography;

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onChange",
  });

  const handleSubmit = (values) => {
    dispatch(register({ values, setError: form.setError }))
      .unwrap()
      .then((data) => {
        dispatch(setNotification({ message: data.message }));
        navigate("/email-verification");
      })
      .catch(() => {});
  };

  return (
    <div>
      <div style={{ marginBottom: "30px", textAlign: "center" }}>
        <Title level={1}>Register</Title>
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
      FormButtons={width < 900 ? (
              <>
                <Row style={{ marginTop: "20px" }}>
                  <Col span={12}>
                    <Typography.Link onClick={() => navigate("/login")}>Log In</Typography.Link>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Link onClick={() => navigate("/reset-password")} target="_blank">
                      Reset Password
                    </Link>
                  </Col>
                </Row>
                  <div style={{ marginTop: '15px', textAlign: 'center' }}>
                    <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                      Register
                    </PrimaryButton>
                  </div>
                </>
            ) : (
              <Row style={{ marginTop: "20px" }}>
                <Col span={8} style={{ marginRight: "30px" }}>
                  <Typography.Link onClick={() => navigate("/login")}>Log In</Typography.Link>
                </Col>
                <Col span={7}>
                  <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                    Register
                  </PrimaryButton>
                </Col>
                <Col span={7}>
                  <Link onClick={() => navigate("/reset-password")} target="_blank">
                    Reset Password
                  </Link>
                </Col>
              </Row>
            )}
      />
    </div>
  );
};

export default Register;
