import { useEffect, useState } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { Input, Skeleton, Typography } from "antd";
import { Controller } from "react-hook-form";

import ErrorBox from "./ErrorBox";

const { Title, Text } = Typography;

const TextFieldLocal = ({ fieldDetails, form, isLoading, isEdit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const fieldContent = watch(fieldDetails.name);

  useEffect(() => {
    setShowPassword(fieldDetails.type !== "input-password");
  }, [fieldDetails]);

  const getType = () => {
    switch (fieldDetails.type) {
      case "input-email":
        return "email";
      case "input-url":
        return "url";
      case "input-number":
        return "number";
      case "input-tel":
        return "tel";
      case "input-password":
        return showPassword ? "text" : "password";
      default:
        return "text";
    }
  };

  const getFormField = () => {
    const Component = fieldDetails.type === "input-text-area" ? Input.TextArea : Input;

    return (
      <>
        <Controller
          name={fieldDetails.name}
          control={control}
          rules={{ required: fieldDetails.isRequired }}
          render={({ field }) => (
            <Component
              id={fieldDetails.name}
              rows={fieldDetails.rows}
              defaultValue={fieldDetails?.defaultValue}
              placeholder={fieldDetails?.placeholder}
              onKeyDown={(e) => {
                if (field.type === "input-number") {
                  if (e.key === "e") {
                    // eslint-disable-next-line no-param-reassign
                    e.preventDefault();
                  }
                  if (e.key === "-") {
                    // eslint-disable-next-line no-param-reassign
                    e.preventDefault();
                  }
                  if (e.key === ",") {
                    // eslint-disable-next-line no-param-reassign
                    e.key = ".";
                  }
                }
              }}
              onKeyUp={(e) => {
                if (Object.prototype.hasOwnProperty.call(field, "min") && !Number.isNaN(parseInt(e.target.value, 10))) {
                  // eslint-disable-next-line no-param-reassign
                  if (e.target.value < field?.min) e.target.value = "";
                }
              }}
              disabled={fieldDetails?.disabled}
              type={getType()}
              status={!!_.get(errors, fieldDetails.name) && "error"}
              {...field}
            />
          )}
        />
        <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
      </>
    );
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (
        <div>
          {!fieldDetails?.hideLabel && (isEdit || !fieldDetails.hideLabelInEdit) && (
            <Title level={5} style={{ marginTop: "5px" }}>
              {fieldDetails?.label}
              {isEdit ? fieldDetails?.isRequired && <span> *</span> : ":"}
            </Title>
          )}
          {!isEdit && fieldDetails?.showContent ? (
            fieldDetails?.type && fieldDetails.type === "input-text-area" ? (
              <Text {...fieldDetails?.props}>{fieldContent}</Text>
            ) : (
              <Title level={5} style={{ marginTop: "0.5em", ...(fieldDetails?.props || {}) }} >
                {fieldContent}
              </Title>
            )
          ) : (
            getFormField()
          )}
          {isEdit && <Text style={{ fontSize: "14px", color: "grey" }}>{fieldDetails?.helpText}</Text>}
        </div>
      )}
    </div>
  );
};

TextFieldLocal.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    helpText: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    hideLabel: PropTypes.bool,
    hideLabelInEdit: PropTypes.bool,
    showContent: PropTypes.bool,
    rows: PropTypes.number,
    props: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default TextFieldLocal;
