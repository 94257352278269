import { notification } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Toasts = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const notificationPayload = useSelector((state) => state.utils.notification);

  useEffect(() => {
    if (notificationPayload) {
      switch (notificationPayload.type) {
        case "error":
          notificationApi.error(notificationPayload);
          break;
        default:
          notificationApi.success(notificationPayload);
          break;
      }
    }
  }, [notificationPayload, notificationApi]);

  return contextHolder;
};

export default Toasts;
