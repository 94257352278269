import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {Card, Col, List, Row, Steps, Tabs, Typography} from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Form from "../../components/form/Form";
import { setNotification } from "../../redux/utilities/actions";
import { PaperStatuses, Roles } from "../../utilities/constants";
import Abstract from "../../components/paper-tabs/Abstract";
import Comments from "../../components/paper-tabs/Comments";
import PrimaryButton from "../../components/PrimaryButton";
import { getPaperById, updatePaper } from "../../redux/papers/actions";
import SelectField from "../../components/form/SelectField";
import { currentUserSelector } from "../../redux/users/selectors";
import Files from "../../components/paper-tabs/Files";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";
import {getUsers} from "../../redux/users/actions";

const paperFormSchema = yup
  .object()
  .shape({
    title: yup.string().typeError("The title is not valid.").required("The title is required."),
    authors: yup.string().typeError("The authors is not valid.").required("The authors is required."),
    keywords: yup.array().typeError("The keywords field is not valid.").min(1, "You must enter at least one keyword.").required("The keywords are required."),
    abstract: yup.string().typeError("The abstract field is not valid.").required("The abstract is required."),
  })
  .required();


const SinglePaper = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const paper = useSelector((state) => (state.papers.data || []).find((i) => i.id.toString() === id.toString()));
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [stepper, setStepper] = useState({
    currentStep: 1,
    steps: [],
  });
  const [activeTabKey, setActiveTabKey] = useState("abstract");
  const currentUser = useSelector(currentUserSelector);
  const { width } = useWindowDimensions();
  const reviewers = useSelector((state) => state.users.data).filter((user) => user.role === Roles.REVIEWER);

  const form = useForm({
    resolver: yupResolver(paperFormSchema),
    mode: "onChange",
  });
  const paperStatus = form.watch("status");
  const paperReviewers = form.watch("reviewers");

  const paperFormFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "title",
        label: "Title",
        type: "input-text",
        isRequired: true,
        hideLabelInEdit: true,
        showContent: true,
      },
      {
        name: "subtitle",
        label: "Subtitle",
        type: "input-text",
        hideLabelInEdit: true,
        showContent: true,
        props: {
            fontWeight: 400
        }
      },
      {
        name: "editor_comments",
        label: "Comment for editors",
        type: "input-text-area",
        showContent: true,
      },
      {
        name: "authors",
        label: "Authors",
        type: "input-text",
        isRequired: true,
        showContent: true,
        size: 7,
      },
      {
        name: "keywords",
        label: "Keywords",
        type: "tags",
        isRequired: true,
        helpText: 'Write the keyword and press Enter to add it.',
        size: 15,
        margin: width < 1300 ? "0" : "5px 20px 0",
      },
    ],
  },
];

  useEffect(() => {
    if (paper) {
      setStepper((oldVal) => {
        let currentSteps = [
          {
            title: "Submit Paper",
            description: `Your paper is submitted - ${new Date(paper.created_at).toLocaleDateString()}`,
          },
          {
            title: "Under Review",
            description: "Your paper is currently being reviewed. You will receive feedback if your paper needs changes, or is accepted/declined via email and in the application.",
          },
        ];
        let currentStepNumber = oldVal.currentStep;

        if (paper.status === PaperStatuses.DECLINED.value) {
          currentSteps = [
            ...currentSteps,
            {
              title: "Paper Declined",
              description: "Your paper has been declined.",
            },
          ];
          currentStepNumber = 3;
        } else if (paper.status === PaperStatuses.ACCEPTED.value) {
          if (paper.has_final_version) {
            currentStepNumber = 3;
          } else {
            currentStepNumber = 2;
          }
          currentSteps = [
            ...currentSteps,
            {
              title: "Paper Accepted",
              description: `Your paper has been accepted. ${paper.has_final_version ? "Thank you for your submission." : "Please upload the final version of the paper for it to be published."}`,
            },
          ];
        } else if (paper.status === PaperStatuses.NEEDS_CHANGES.value) {
          currentSteps = [
            ...currentSteps,
            {
              title: "Changes Needed",
              description: (
                <>
                  The paper has been reviewed and needs changes. Please go to the&nbsp;
                  <span style={{ color: "blue", textDecoration: "underline" }} onClick={() => setActiveTabKey("comments")}>
                    Comments
                  </span>{" "}
                  tab to see the changes requested. Once the changes are done, upload a new version of your paper.
                </>
              ),
            },
          ];
          currentStepNumber = 2;
        }

        return {
          currentStep: currentStepNumber,
          steps: currentSteps,
        };
      });

      Object.keys(paper).forEach((k) => {
        form.setValue(k, paper[k]);
      });
    }
  }, [paper]);

  useEffect(() => {
    dispatch(getPaperById(id));
  }, [id]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleSubmit = (values) => {
    dispatch(updatePaper({ values, setError: form.setError }))
      .unwrap()
      .then(() => {
        dispatch(setNotification({ message: "The paper has been updated." }));
        setEdit(false);
      });
  };

  useEffect(() => {
    if (paper) {
      if ((paperStatus && paper.status !== paperStatus) || (paperReviewers && paperReviewers.length !== paper.reviewers.length)) {
        form.handleSubmit(handleSubmit)();
      }
    }
  }, [paper, paperStatus, paperReviewers]);

  return (
    paper && (
      <div>
        <Card bordered={false}>
          <Row>
            <Col span={width < 1300 ? 24 : 20} style={{ paddingRight: "20px" }}>
              <PrimaryButton onClick={() => navigate("/papers")}>
                <ArrowLeftOutlined /> Back
              </PrimaryButton>
              <div>
                <Form
                  steps={paperFormFields}
                  form={form}
                  isLoading={form.formState.isSubmitting}
                  onSubmit={handleSubmit}
                  FormButtons={
                    <div style={{ marginTop: "15px", textAlign: "center" }}>
                      <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                        Submit
                      </PrimaryButton>
                    </div>
                  }
                  isEdit={edit}
                />
              </div>
            </Col>
            <Col span={width < 1300 ? 24 : 4}>
              <div>
                <PrimaryButton onClick={() => setEdit((lastVal) => !lastVal)} style={{ marginBottom: "10px" }}>
                <EditOutlined /> Edit
              </PrimaryButton>
              </div>
              <PrimaryButton onClick={() => navigate("/papers")}>
                <DeleteOutlined /> Delete
              </PrimaryButton>
              <div style={{ marginTop: "10px" }}>
                {[Roles.ADMIN, Roles.REVIEWER].includes(currentUser?.role) && (
                    <SelectField
                      fieldDetails={{
                        name: "status",
                        type: "select",
                        label: "Status",
                        placeholder: "Select a status",
                        options: PaperStatuses.asList(),
                        size: 24,
                      }}
                      form={form}
                    />
                  )}
                  {currentUser?.role === Roles.ADMIN && (
                    <SelectField
                      fieldDetails={{
                        name: "reviewers",
                        type: "select",
                        multiple: true,
                        label: "Reviewers",
                        placeholder: "Select reviewers",
                        options: reviewers.map((user) => ({ value: user.id, label: user.email })),
                        size: 24,
                      }}
                      form={form}
                    />
                  )}
                </div>
              {currentUser?.role === Roles.AUTHOR && paper?.reviewers && (
                      <div>
                      <Typography.Title level={4}>Reviewers</Typography.Title>
                      <List
                      itemLayout="horizontal"
                      dataSource={reviewers.filter((i) => paper?.reviewers?.includes(i.id))}
                      renderItem={(item, index) => (
                        <List.Item>
                          <List.Item.Meta
                              title={<Typography.Text>{index + 1}. {item.email}</Typography.Text>}
                          />
                        </List.Item>
                      )}
                    />
                    </div>
              )}
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={width < 1300 ? 24 : 18}>
            <Card style={{ marginTop: "20px" }}>
              <Tabs
                type="card"
                activeKey={activeTabKey}
                onTabClick={(key) => setActiveTabKey(key)}
                items={[
                  {
                    label: "Abstract",
                    key: "abstract",
                    children: <Abstract form={form} isEdit={edit} />,
                  },
                  {
                    label: "Comments",
                    key: "comments",
                    children: <Comments paperId={paper.id} />,
                  },
                  {
                    label: "Files",
                    key: "files",
                    children: <Files paperId={paper.id} />,
                  },
                ]}
              />
            </Card>
          </Col>
          <Col span={width < 1300 ? 24 : 6}>
            <div style={{ width: width < 1300 ? "100%" : "90%", margin: width < 1300 ? "20px 0" : "20px 0 auto 0", float: "right" }}>
              <Card>
                <Steps direction="vertical" current={stepper.currentStep} items={stepper.steps} />
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

export default SinglePaper;
