import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Form from "../../components/form/Form";
import PrimaryButton from "../../components/PrimaryButton";
import { resendVerificationCode } from "../../redux/authentication/actions";
import { setNotification } from "../../redux/utilities/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const resendVerificationCodeSchema = yup
  .object()
  .shape({
    email: yup.string().typeError("The email is not valid.").email("Please enter a valid email.").required("Please enter a valid email."),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "email",
        label: "Email address",
        type: "input-email",
        isRequired: true,
        size: 24,
      },
    ],
  },
];

const { Title, Link } = Typography;

const ResendVerificationCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(resendVerificationCodeSchema),
    mode: "onChange",
  });

  const handleSubmit = (values) => {
    dispatch(resendVerificationCode(values))
      .unwrap()
      .then((data) => {
        dispatch(setNotification({ message: data.message }));
        navigate("/email-verification/confirm");
      });
  };

  return (
    <div>
      <div style={{ marginBottom: "30px", textAlign: "center" }}>
        <Title level={1}>Resend Verification Code</Title>
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
        FormButtons={
          <Row style={{ marginTop: "20px" }}>
            <Col span={width < 900 ? 24 : 17}>
              <Link onClick={() => navigate("/register")}>Don&apos;t have an account? Register</Link>
            </Col>
            <Col span={width < 900 ? 24 : 7} style={{ marginTop: '15px', textAlign: 'center' }}>
              <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                Resend Code
              </PrimaryButton>
            </Col>
          </Row>
        }
      />
    </div>
  );
};

export default ResendVerificationCode;
