import PropTypes from "prop-types";
import { Checkbox as AntCheckbox } from "antd";
import _ from "lodash";
import {Controller} from "react-hook-form";

import ErrorBox from "./ErrorBox";

const Checkbox = ({ fieldDetails, form }) => {
    const { formState: { errors }, control } = form;

    return (
      <div>
        <Controller
            name={fieldDetails.name}
            control={control}
            rules={{ required: fieldDetails.isRequired }}
            render={({ field }) => (
                <AntCheckbox {...field} checked={field.value}>{fieldDetails.label}{fieldDetails?.isRequired && "*"}</AntCheckbox>
            )}
        />
        <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
      </div>
    )
};

Checkbox.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    helpText: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    hideLabel: PropTypes.bool,
    hideLabelInEdit: PropTypes.bool,
    showContent: PropTypes.bool,
    rows: PropTypes.number,
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default Checkbox;
