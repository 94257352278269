import { createLogger } from "redux-logger/src";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authentication/reducer";
import utilsReducer from "./utilities/reducer";
import papersReducer from "./papers/reducer";
import notificationsReducer from "./notifications/reducer";
import usersReducer from "./users/reducer";
import commentsReducer from "./comments/reducer";
import filesReducer from "./files/reducer";

import { injectStore } from "../utilities/api";

const logger = createLogger();

const combinedReducer = combineReducers({
  auth: authReducer,
  papers: papersReducer,
  notifications: notificationsReducer,
  utils: utilsReducer,
  users: usersReducer,
  comments: commentsReducer,
  files: filesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/logout/fulfilled") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === "production" ? getDefaultMiddleware() : [...getDefaultMiddleware(), logger]),
});

injectStore(store);

export default store;
