import {useEffect, useState} from "react";

import {Col, Row, Steps, Typography} from "antd";
import Title from "antd/es/skeleton/Title";
import PropTypes from "prop-types";

import FormFields from "./FormFields";
import PrimaryButton from "../PrimaryButton";

const Form = ({ onSubmit, steps, form, isLoading, FormButtons, isEdit }) => {
  const { trigger, formState } = form;
  const [page, setPage] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setPage(steps[currentStep]);
  }, [currentStep]);

  const handleNext = async () => {
      const currentStepFieldNames = steps[currentStep].fields.map((i) => i.name).filter((i) => ![undefined, null].includes(i));
      // eslint-disable-next-line no-unused-vars
      const _ = formState.errors
      console.log(form.getValues())
      trigger(currentStepFieldNames).then((isValid) => {
          if (isValid) {
              setCurrentStep((oldVal) => oldVal + 1)
          }
      });
  }

   const handleBack = () => {
     setCurrentStep((oldVal) => oldVal - 1);
  }

  return (
     <div>
       {steps.length > 1 && <Steps
           current={currentStep}
          items={steps.map((i, index) => ({ key: index, title: i.title}))}
        />}
       {page && (
           <Row>
          <Row>
            {page?.title && (
              <Col span={24} style={{ paddingBottom: 2, textAlign: "center" }}>
                <Typography>
                  <Title>{page.title}</Title>
                </Typography>
              </Col>
            )}
          </Row>
          <form style={{ width: "100%", wordWrap: "break-word" }} onSubmit={form.handleSubmit(onSubmit)}>
            <Row>
              <FormFields isEdit={isEdit} form={form} page={page} isLoading={isLoading} />
              <Row style={{ width: '100%', marginTop: '15px' }}>
                 <Col span={12}>
                   {steps.length > 1 && currentStep > 0 && (
                    <PrimaryButton style={{ float: 'right', marginRight: '15px' }} size="large" onClick={handleBack}>Back</PrimaryButton>
                   )}
                 </Col>
                <Col span={steps.length > 1 ? 12 : 24}>
                  {currentStep + 1 === steps.length ? (
                      isEdit && FormButtons && <div style={{ float: 'left', width: '100%' }}>{FormButtons}</div>
                  ) : (
                      <PrimaryButton style={{ float: 'left', marginRight: '15px' }} size="large" onClick={handleNext}>Next</PrimaryButton>
                  )}
                </Col>
              </Row>
            </Row>
          </form>
        </Row>
       )}
     </div>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool,
  FormButtons: PropTypes.element,
  isEdit: PropTypes.bool,
};

Form.defaultProps = {
  onSubmit: () => {},
  isEdit: true,
  isLoading: false,
  FormButtons: null,
};

export default Form;
