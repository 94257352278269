import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getCurrentUser = createAsyncThunk("users/getCurrentUser", (_, { rejectWithValue }) => makeRequest("/auth/me/", "GET", null, rejectWithValue));

export const updateProfile = createAsyncThunk("users/updateProfile", ({ values, setError }, { rejectWithValue }) => makeRequest("/auth/me/", "PATCH", values, rejectWithValue, setError));

export const getUsers = createAsyncThunk("users/getUsers", (_, { rejectWithValue }) => makeRequest("/users/", "GET", null, rejectWithValue));

export const patchUser = createAsyncThunk("users/patchUser", (values, { rejectWithValue }) => makeRequest(`/users/${values.id}/`, "PATCH", values, rejectWithValue));
