import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";

import { getUsers, patchUser } from "../../redux/users/actions";
import Table from "../../components/Table";
import {Roles} from "../../utilities/constants";


const columns = (dispatch) => [
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      addSearch: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      addSearch: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      addSearch: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      addSearch: true,
    },
    {
      title: "Verified",
      dataIndex: "is_verified",
      key: "is_verified",
      render: (_, record) => <Checkbox checked={record.is_verified} onChange={() => dispatch(patchUser({ id: record.id, is_verified: !record.is_verified }))} />,
    },
    {
      title: "Blocked",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) => <Checkbox checked={!record.is_active} onChange={() => dispatch(patchUser({ id: record.id, is_active: !record.is_active }))} />,
    },
  ];

const Users = () => {
  const users = useSelector((state) => state.users.data) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return <div>{users && <Table data={users.filter((i) => i.role === Roles.AUTHOR)} columns={columns(dispatch)} />}</div>;
};

export default Users;
