import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getComments = createAsyncThunk("comments/list", ({ paperId }, { rejectWithValue }) => makeRequest(`/papers/${paperId}/comments/`, "GET", null, rejectWithValue));

export const addComment = createAsyncThunk("comments/add", ({ paperId, values }, { rejectWithValue }) => makeRequest(`/papers/${paperId}/comments/`, "POST", values, rejectWithValue));

export const updateComment = createAsyncThunk("comments/update", ({ paperId, values }, { rejectWithValue }) =>
  makeRequest(`/papers/${paperId}/comments/?comment_id=${values.id}`, "PUT", values, rejectWithValue),
);

export const deleteComment = createAsyncThunk("comments/delete", ({ paperId, commentId }, { rejectWithValue }) =>
  makeRequest(`/papers/${paperId}/comments/?comment_id=${commentId}`, "DELETE", null, rejectWithValue),
);
