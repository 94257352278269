import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import router from "./router/Router";
import store from "./redux/store";
import Toasts from "./components/Toasts";

Sentry.init({
  dsn: "https://8c509ac2569a619b333d63bf59802ac0@o4506333426614272.ingest.sentry.io/4506333437165568",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/app\.roads\.mare\.org\.mk\/api/],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
    <Toasts />
  </Provider>
);

export default App;
