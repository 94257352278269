import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";

const { Title, Paragraph } = Typography;

const Banner = ({ field }) => (
  <div>
    <Row>
      <Col span={21}>
        <div>
          {field?.contents?.map((item) => {
            if (item.type === "p") {
              if (Array.isArray(item.content)) {
                return item.content.map((t) => {
                  if (typeof t === "object") {
                    return (
                      <div key={item.id + t.value}>
                        <Paragraph {...t?.props}>
                          <Title>{t.value}</Title>
                        </Paragraph>
                        <br />
                      </div>
                    );
                  }
                  return (
                    <div key={item.id}>
                      <Paragraph>{t}</Paragraph>
                      <br />
                    </div>
                  );
                });
              }
              return (
                <div key={`${item.id}-${item.title}-${item.content}`}>

                  {item?.content && <Paragraph>{item.content}</Paragraph>}
                </div>
              );
            }
            if (item.type === "ul") {
              return (
                <div key={item.id + item.title}>
                  {item?.title && <Paragraph {...item?.props}>{item.title}</Paragraph>}
                  <ul key={item.id}>
                    {item.content.map((li) => (
                      <li key={li}>{li}</li>
                    ))}
                  </ul>
                </div>
              );
            }
            if (item.type.includes("h")) {
              return (
                <div key={item.id + item.title}>
                  {item?.content && (
                    <Title {...item?.props} level={item?.level || 5}>
                      {item.content}
                    </Title>
                  )}
                </div>
              );
            }
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
          })}
        </div>
      </Col>
    </Row>
  </div>
);

Banner.propTypes = {
  field: PropTypes.node.isRequired,
};

export default Banner;
