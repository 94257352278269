import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Comment } from "@ant-design/compatible";
import moment from "moment";
import { Avatar, Tooltip, Typography } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";

import { addComment, deleteComment, getComments, updateComment } from "../../redux/comments/actions";
import { getCommentsForPaper } from "../../redux/comments/selectors";
import PrimaryButton from "../PrimaryButton";
import Form from "../form/Form";
import { setNotification } from "../../redux/utilities/actions";
import { currentUserSelector } from "../../redux/users/selectors";

const commentSchema = yup
  .object()
  .shape({
    content: yup.string().typeError("The comment is not valid.").required("You must enter a comment."),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    buttonInForm: true,
    fields: [
      {
        name: "content",
        label: "Comment",
        type: "input-text-area",
        isRequired: true,
        hideLabel: true,
        placeholder: "Enter your comment here...",
        size: 20,
      },
    ],
  },
];

const { Title } = Typography;

const Comments = ({ paperId }) => {
  const dispatch = useDispatch();
  const comments = useSelector(getCommentsForPaper(paperId));
  const [editId, setEditId] = useState(null);
  const bottomScrollRef = useRef();
  const currentUser = useSelector(currentUserSelector);

  const form = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onChange",
    defaultValues: {
      id: null,
      content: "",
    },
  });

  useEffect(() => {
    if (bottomScrollRef?.current) {
      bottomScrollRef.current.scrollIntoView();
    }
  }, [bottomScrollRef.current]);

  useEffect(() => {
    if (editId) {
      const comment = comments.find((i) => i.id === editId);
      Object.keys(comment).forEach((key) => form.setValue(key, comment[key]));
    }
  }, [editId]);

  useEffect(() => {
    dispatch(getComments({ paperId }));
  }, []);

  const handleSubmit = (values) => {
    if (values.id) {
      dispatch(updateComment({ paperId, values }))
        .unwrap()
        .then(() => {
          form.reset();
          dispatch(setNotification({ message: "The comment has been updated." }));
        });
    } else {
      dispatch(addComment({ paperId, values }))
        .unwrap()
        .then(() => {
          form.reset();
          dispatch(setNotification({ message: "The comment has been added." }));
        });
    }
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment({ paperId, commentId }))
      .unwrap()
      .then(() => dispatch(setNotification({ message: "The comment has been deleted." })));
  };

  return (
    <div>
      <div style={{ height: "350px", overflowY: "scroll", marginBottom: "15px", ...(comments.length === 0 && { alignItems: "center", display: "flex", justifyContent: "center" }) }}>
        {comments.length > 0 ? (
          comments.map((i) => (
            <div key={`${i.content}-${i.created_at}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", margin: "8px 5px", borderRadius: "5px", padding: "0 15px" }}>
              <Comment
                actions={
                  currentUser.id === i.created_by.id && [
                    <span key="comment-basic-reply-to" onClick={() => setEditId(i.id)}>
                      Edit
                    </span>,
                    <span
                      key="comment-basic-delete"
                      onClick={() => {
                        form.reset();
                        handleDeleteComment(i.id);
                      }}
                    >
                      Delete
                    </span>,
                  ]
                }
                author={i.created_by.full_name}
                avatar={<Avatar src={`https://ui-avatars.com/api/?name=${i.created_by.full_name}&background=${currentUser.id === i.created_by.id ? "1677ff" : "A9A9A9"}`} alt="Han Solo" />}
                content={i.content}
                datetime={
                  <Tooltip title={moment(i.created_at).format("YYYY-MM-DD HH:mm:ss")}>
                    <span>{moment(i.created_at).fromNow()}</span>
                  </Tooltip>
                }
              />
            </div>
          ))
        ) : (
          <Title level={3} style={{ fontWeight: "300", color: "silver" }}>
            There are no comments.
          </Title>
        )}
        <span ref={bottomScrollRef} />
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
        FormButtons={
          <div style={{ marginLeft: "20px", textAlign: "center" }}>
            <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
              Submit
            </PrimaryButton>
          </div>
        }
      />
    </div>
  );
};

Comments.propTypes = {
  paperId: PropTypes.number.isRequired,
};

export default Comments;
