import { createSlice } from "@reduxjs/toolkit";

import { login, logOut, verifyAccount } from "./actions";
import { isUserAuthenticated, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { LocalStorageName } from "../../utilities/constants";

const initialState = {
  loading: false,
  isAuthenticated: isUserAuthenticated(),
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            access_expiration: payload.access_expiration,
            refresh_expiration: payload.refresh_expiration,
          }),
        );
        return {
          ...state,
          isAuthenticated: true,
        };
      })

      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            access_expiration: payload.access_expiration,
            refresh_expiration: payload.refresh_expiration,
          }),
        );
        return {
          ...state,
          isAuthenticated: true,
        };
      })

      .addCase(logOut.fulfilled, (state) => {
        window.localStorage.removeItem(LocalStorageName);
        return {
          ...state,
          isAuthenticated: false,
        };
      })

      .addMatcher(isPendingAction("authentication"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("authentication"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("authentication"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default authenticationSlice.reducer;
