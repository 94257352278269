import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Form from "../../components/form/Form";
import PrimaryButton from "../../components/PrimaryButton";
import { verifyAccount } from "../../redux/authentication/actions";
import { setNotification } from "../../redux/utilities/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const emailVerificationSchema = yup
  .object()
  .shape({
    token: yup.string().typeError("The code is not valid.").required("The code is required."),
  })
  .required();

const formFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "token",
        label: "Code",
        type: "input-text",
        isRequired: true,
        size: 24,
      },
    ],
  },
];

const { Title, Link } = Typography;

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(emailVerificationSchema),
    mode: "onChange",
  });

  const sendCode = (token) => {
    dispatch(verifyAccount({ token }))
      .unwrap()
      .then((data) => {
        dispatch(setNotification({ message: data.message }));
        navigate("/papers");
      });
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      form.setValue("token", searchParams.get("token"));
      sendCode(searchParams.get("token"));
    }
  }, [searchParams]);

  const handleSubmit = (values) => {
    sendCode(values.token);
  };

  return (
    <div>
      <div style={{ marginBottom: "30px", textAlign: "center" }}>
        <Title level={1}>Verify Email</Title>
      </div>
      <Form
        steps={formFields}
        form={form}
        isLoading={form.formState.isSubmitting}
        onSubmit={handleSubmit}
        FormButtons={
          <Row style={{ marginTop: "20px" }}>
            <Col span={width < 900 ? 24 : 17}>
              <Link onClick={() => navigate("/email-verification/resend")}>Didn&apos;t receive a code? Resend it</Link>
            </Col>
            <Col span={width < 900 ? 24 : 7} style={{ marginTop: '15px', textAlign: 'center' }}>
              <PrimaryButton htmlType="submit" disabled={form.formState.isSubmitting}>
                Verify
              </PrimaryButton>
            </Col>
          </Row>
        }
      />
    </div>
  );
};

export default EmailVerification;
